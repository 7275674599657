import { AfterViewInit } from '@angular/core';
import * as moment from 'moment';

export class BaseComponent implements AfterViewInit {

  breadcrumbs = [];

  constructor() {
    Array.prototype['sortBy'] = function (p) {
      return this.slice(0).sort(function (a, b) {
        return (a[p] > b[p]) ? 1 : (a[p] < b[p]) ? -1 : 0;
      });
    };
    Array.prototype['groupBy'] = function (key) {
      return this.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };
    Array.prototype['remove'] = function (key, value) {
      let index = 0;
      let found = false;
      for (index = 0; index < this.length; index++) {
        if (this[index][key] === value) {
          found = true;
          break;
        }
      }
      this.splice(index, 1);
    };
  }

  sortData(sortBy, sortOrder) { }

  ngAfterViewInit() {
    this.setUpSorting();
    $('button.show-hide-panel').on('click', function (e) {
      $(e.currentTarget).toggleClass('hidden');
      $(e.currentTarget).parent().siblings('.sub-panel-body').slideToggle('slow');
    });
  }

  setUpSorting() {
    const that = this;
    $('thead').on('click', 'th', function (e) {
      const th = e.currentTarget;
      const sortBy = th.getAttribute('data-sort-by');
      let sortOrder = 'asc';
      if (sortBy) {
        if ($(th).hasClass('asc')) {
          $(th).removeClass('asc').addClass('desc');
          sortOrder = 'desc';
        } else if ($(th).hasClass('desc')) {
          $(th).removeClass('desc').addClass('asc');
        } else {
          $(th).siblings().removeClass('asc').removeClass('desc');
          $(th).addClass('asc');
        }
        that.sortData(sortBy, sortOrder);
      }
    });
  }

  startLoader() {
    $('.spinner').show();
  }

  stopLoader() {
    $('.spinner').hide();
  }

  changeAccordianIcon(event) {
    const $accordian = $(event.currentTarget).find('span');
    if ($accordian.hasClass('glyphicon-plus')) {
      $accordian.removeClass('glyphicon-plus');
      $accordian.addClass('glyphicon-minus');
    } else {
      $accordian.removeClass('glyphicon-minus');
      $accordian.addClass('glyphicon-plus');
    }
  }

  guid() {
    function s4() {
      return Math.floor((1 + Math.random()) * 0x10000)
        .toString(16)
        .substring(1);
    }
    return s4() + s4() + '-' + s4() + '-' + s4() + '-' +
      s4() + '-' + s4() + s4() + s4();
  }

  b64toBlob(b64Data, contentType, sliceSize?) {
    contentType = contentType || '';
    sliceSize = sliceSize || 512;

    const byteCharacters = atob(b64Data);
    const byteArrays = [];

    for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
      const slice = byteCharacters.slice(offset, offset + sliceSize);
      const byteNumbers = new Array(slice.length);
      for (let i = 0; i < slice.length; i++) {
        byteNumbers[i] = slice.charCodeAt(i);
      }
      const byteArray = new Uint8Array(byteNumbers);
      byteArrays.push(byteArray);
    }

    const blob = new Blob(byteArrays, { type: contentType });
    return blob;
  }

  searchKey(obj, key) {
    const result = [];
    if (!obj || !key) {
      return result;
    }
    Object.keys(obj).forEach(function (k) {
      if (k.indexOf(key) !== -1) {
        result.push(obj[k]);
      }
    });
    return result;
  }

  clone(obj) {
    return JSON.parse(JSON.stringify(obj));
  }

  static validateDate(date) {
    if(date && (moment(date, 'MM/DD/YYYY', true).isValid() || moment(date, 'YYYY-MM-DDTHH:mm:ss', true).isValid())) {
      return true;
    }
    return false;
  }

}
